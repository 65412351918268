<template>
  <div>
    <div v-if="success === 'Paid'">
      <success />
    </div>
    <div v-if="success === 'Pending'">
      <pending-pay />
    </div>
    <div v-if="success!== 'Paid' && success!== 'Pending'">
      <pay-error />
    </div>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import PayError from '@/views/pages/redirect-payment/components/PayError.vue'
import PendingPay from '@/views/pages/redirect-payment/components/PendingPay.vue'
import Success from '@/views/pages/redirect-payment/components/Success.vue'

export default {
  name: 'Redirect',
  components: {
    PayError,
    PendingPay,
    Success,
  },
  data() {
    return {
      success: 'Pending',
    }
  },
  beforeMount() {
    const parameters = new URLSearchParams(window.location.search)
    const orderId = parameters.get('order_id')
    this.getPaymentStatus(orderId)
  },
  methods: {
    getPaymentStatus(orderId) {
      store.dispatch('account/conektaReturn', { orderId }).then(
        response => {
          store.dispatch('account/updateUserInformation')
          this.success = response.data.payment.status
          return Promise.resolve(response)
        },
        error => {
          store.dispatch('account/updateUserInformation')
          router.push('/')
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style scoped>

</style>
