<template>
  <div>
    <div class="p-2 p-sm-3">
      <div class="w-100 text-center mt-3">
        <b-img
          fluid
          class="img-w-300"
          :src="imgUrl"
          alt="Error"
        />
        <h1 class="mb-1 mt-3">
          Error en el pago 🤔
        </h1>
        <p>
          Hubo un error al momento de realizar tu pago verifica que tengas fondos en tu tarjeta.
        </p>
        <p class="mb-2">
          Si el error persiste comunícate con soporte técnico.
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          to="products"
        >Inicio</b-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BImg, BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.img-w-300{
  width: 300px;
}
</style>
